<template>
  <v-autocomplete
    v-model="idBranchOffice"
    :items="branchOfficeList"
    @change="branchChange"
    :label="branchLabel"
    clearable
    :disabled="isDisabled"
  />
</template>
<script>
export default {
  props: {
    branchLabel: "",
    branchOfficeList: [],
    isDisabled: false,
  },
  data() {
    return {
      idBranchOffice: "",
    };
  },
  methods: {
    branchChange() {
      this.$emit("branchChange", this.idBranchOffice);
    },
    reloadComponentClean() {
      this.idBranchOffice = "";
    },
  },
};
</script>
