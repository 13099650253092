<template>
  <v-dialog
    v-model="dialogM.dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="blue-fmedical">
        <v-toolbar-title class="pt-4">
          <p class="text-center">
            <i class="feather icon-package mr-1"></i>
            Detalle del movimiento |
            {{ model.idMovement }}
          </p>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialogM.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="container">
        <div class="row">
          <div class="col-md-12 pb-0">
            <div class="col-md-12 pb-0">
              <p class="pt-3">
                <i class="fa fa-info mr-2 text-fmedical-blue"></i>
                <strong>
                  <span>Información de movimiento </span>
                </strong>
              </p>
            </div>
            <div class="card border-light">
              <div class="card-header card-header-detail regular">
                <i class="feather icon-user mr-2 text-fmedical-blue" />
                <strong>
                  <span> Realizado por:</span>
                </strong>
                {{ model.userName }}
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-xs-12 col-md-3">
                    <p class="pt-3">
                      <i class="fa fa-paperclip mr-2 text-fmedical-blue"></i>
                      <strong>
                        <span> ID Movimiento:</span>
                      </strong>
                      {{ model.idMovement }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-3">
                    <p class="pt-3">
                      <i
                        class="feather icon-calendar mr-2 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> Fecha de movimiento:</span>
                      </strong>
                      {{ model.dateMovement }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-3">
                    <p class="pt-3">
                      <i
                        class="feather icon-clipboard  mr-2 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> ID Principal:</span>
                      </strong>
                      {{ model.idPrincipal }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-3">
                    <p class="pt-3">
                      <i
                        class="feather icon-clipboard  mr-2 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> ID Secundario:</span>
                      </strong>
                      {{ model.idSecondary }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-6">
                    <p class="pt-0">
                      <i class="feather icon-truck mr-1 text-fmedical-blue"></i>
                      <strong>
                        <span> Sucursal de origen:</span>
                      </strong>
                      {{ model.branchOfficeMovement }}
                    </p>
                  </div>
                  <div class="col-xs-12 col-md-6">
                    <p class="pt-0">
                      <i
                        class="feather icon-layout mr-1 text-fmedical-blue"
                      ></i>
                      <strong>
                        <span> Modulo:</span>
                      </strong>
                      {{ model.entity }} | <strong> {{ utilFront.typeMovement(model.movement) }}  </strong>| {{ model.originMovement }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 pb-0">
              <p class="pt-5">
                <i class="feather icon-server mr-2 text-fmedical-blue"></i>
                <strong>
                  <span>Información de movimiento en base de datos</span>
                </strong>
              </p>
            </div>
          </div>
          <div class="col-md-12 pb-7">
            <code-mirror
              ref="code"
              :id="'form_01'"
              :show_js="true"
              :id_js="'form_js_01'"
              :content_js="model.dataMovement"
              :taman="tam"
            />
          </div>
          <div class="col-md-12 pb-4"></div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";

export default {
  data() {
    return {
      search: "",
      utilFront: this.$UtilFront,
      show: false,
      tam: 200,
    };
  },
  components: {
    ButtonPrintComponent,
  },
  props: {
    dialogM: Object,
    model: {
      required: true,
      default: {},
    },
    headers: {
      require: true,
      default: {},
    },
  },
  watch: {
    model() {
      if (this.model.idMovement) {
        this.modal = true;
        const utf8Bytes = new TextEncoder().encode(this.model.dataMovement);
        const jsonSizeInBytes = utf8Bytes.length;
        jsonSizeInBytes > 200 ? (this.tam = jsonSizeInBytes) : (this.tam = 250);
        this.$nextTick(() => {
          this.$refs.code.refresh();
        });
      }
    },
  },
};
</script>
