<template>
  <div>
    <v-chip :color="colorStatus(status)" dark class="m-1 regular">
      {{ nameStatus(status) }}
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    status: 0,
    statusName: "",
    medicalPrecription: false,
  },
  methods: {
    colorStatus(status) {
      const color = {
        1: "default",
        2: "info",
        3: this.medicalPrecription ? "red" : "primary",
        4: "orange",
        5: "green",
        6: "grey",
        7: "red",
      };
      return color[status] || "white";
    },
    nameStatus(status) {
      const name = {
        1: this.medicalPrecription ? "Pendientes" : "Pendiente de Finalizar",
        2: this.medicalPrecription ? "Dispensado" : "Picking",
        3: this.medicalPrecription ? "Cancelado" : "Validación de Picking",
        4: "Pendiente de Envío",
        5: "Tránsito",
        6: "Recepción",
        7: "Cancelado",
      };
      return name[status] || "No definido";
    },
  },
};
</script>
