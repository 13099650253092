<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :search="search"
      :items="modelTable.content"
      fixed-header
      dense
      key="footerProps"
      :footer-props="{
        'items-per-page-text': $t('table.item_table'),
        'items-per-page-all-text': $t('table.all'),
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_results_available')"
      :no-results-text="$t('table.no_results_search')"
      :server-items-length="modelTable.totalElements"
      :options.sync="options"
      :page.sync="page"
    >
      <template v-slot:top>
        <div class="row justify-end p-2 pb-0">
          <div class="col-sm-12 pb-0 col-md-6 col-lg-3 col-xl-3">
            <vc-date-picker
              :locale="lang"
              color="green"
              v-model="dateMov"
              is-range
              @input="datePickerChangeHandler"
              popover-align="center"
            >
              <template v-slot="{ inputEvents }">
                <div class="flex justify-center items-center">
                  <v-text-field
                    :readonly="true"
                    v-on="inputEvents.start"
                    :value="modalDateRangeCalendar"
                    :label="$t('label.date_range')"
                    @change="dateFilterChange()"
                    :append-outer-icon="
                      modalDateRangeCalendarValid
                        ? 'mdi-eraser'
                        : 'mdi-calendar'
                    "
                    @click:append-outer="modalDateRangeClean"
                  ></v-text-field>
                </div>
              </template>
            </vc-date-picker>
          </div>
          <div class="col-sm-12 pb-0 col-md-6 col-lg-3 col-xl-3">
            <AllBranchOfficeComponentClear
              ref="branchOfficeDestination"
              v-on:branchChange="branchChange"
              branchLabel="Sucursal origen"
              :branchOfficeList="branchOfficeList"
              :isDisabled="isDisabled"
            />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <v-autocomplete
              class="br"
              v-model="userFilter"
              :items="listUserFilter"
              @change="userFilterChange(true)"
              :label="
                branch_general == '0'
                  ? 'SUCURSAL GENERAL'
                  : !pagesAtributes.sortFilters.movementsBranchOfficeFilter
                  ? 'Elige una sucursal origen'
                  : 'Realizado por'
              "
              data-vv-name="select"
              clearable
              :disabled="
                branch_general == '0' ||
                  !pagesAtributes.sortFilters.movementsBranchOfficeFilter
              "
            ></v-autocomplete>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <v-autocomplete
              class="br"
              @change="statusFilterEntityChange()"
              v-model="statusFilterEntity"
              :items="typeEntityMovement"
              clearable
              label="Descripción"
              data-vv-name="select"
            ></v-autocomplete>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <v-autocomplete
              class="br"
              @change="typeMovementModuleFilterChange()"
              v-model="typeMovementModule"
              :items="typeModuleMovement"
              clearable
              label="Modulo"
              data-vv-name="select"
            ></v-autocomplete>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3">
            <v-autocomplete
              class="br"
              @change="typeMovementFilterChange()"
              v-model="typeMovement"
              :items="statusTypeMovement"
              clearable
              label="Tipo de movimiento"
              data-vv-name="select"
            ></v-autocomplete>
          </div>
          <div
            class="col-sm-12 col-md-6 col-lg-5 col-xl-4 pb-1 pr-6 text-right"
          >
            <v-text-field
              v-model="search"
              v-on:keyup.enter="searchValue"
              :suffix="search == '' ? '' : 'enter'"
              class="br"
              :disabled="loading_search"
              :label="$t('table.search')"
              ><template v-slot:append>
                <span class="pt-2">
                  <i
                    class="feather icon-corner-down-left"
                    v-show="search != ''"
                  ></i>
                </span> </template
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.movement`]="{ item }">
        {{ utilFront.typeMovement(item.movement) }}
      </template>

      <template v-slot:[`item.originMovement`]="{ item }">
        {{ getNameEntity(item.originMovement) }}
      </template>

      <template v-slot:[`item.entity`]="{ item }">
        {{ getNameEntityModule(item.entity) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span
              class="hand mr-2"
              @click="detail(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-eye"></i>
            </span>
          </template>
          <span>Detalle</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <ButtonPrintComponent />
  </div>
</template>

<script>
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";
import ChipTableCommonPagination from "@/common/table/ChipTableCommonPagination";
import { ExcelRequest } from "@/core/request";

import AllBranchOfficeComponentClear from "@/common/select/AllBranchOfficeComponentClear";
import { BranchOfficeRequest, OrderRequest } from "@/core/request";

import typeEntityMovement from "@/assets/json/typeEntityMovement.json";
import typeModuleMovement from "@/assets/json/typeModuleMovement.json";

import { mapState } from "vuex";
import moment from "moment";
export default {
  props: {
    headers: [],
    shipments: false,
    isPdf: false,
    isPdfOw: false,
    pagesAtributes: Object,
    methodPage: Function,
    modelTable: Object,
  },
  components: {
    ButtonPrintComponent,
    ChipTableCommonPagination,
    AllBranchOfficeComponentClear,
  },
  data() {
    return {
      search: "",
      dataList: [],
      options: {},
      page: 1,
      branchOfficeList: [],
      header: this.headers,
      loading_search: false,
      utilFront: this.$UtilFront,
      isDisabled: false,
      userFilter: "",
      typeEntityMovement: typeEntityMovement,
      typeModuleMovement: typeModuleMovement,
      listUserFilter: [],
      statusFilter: null,
      statusFilterEntity: null,
      typeMovement: null,
      typeMovementModule: null,
      branch_general: null,
      statusTypeMovement: [
        {
          value: "1",
          text: "Registro",
        },
        {
          value: "2",
          text: "Actualización",
        },
        {
          value: "3",
          text: "Deshabilitado",
        },
        {
          value: "4",
          text: "Modificación de estado",
        },
      ],
      dateMov: "",
      dateModelMov: {
        startDate: "",
        endDate: "",
      },
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY",
      },
    };
  },
  computed: {
    ...mapState("locales", ["lang"]),
    modalDateRangeCalendar() {
      if (
        this.dateMov == "" ||
        this.dateMov == null ||
        this.dateMov == undefined
      ) {
        return "";
      }

      this.dateModelMov.startDate = moment(this.dateMov.start).format(
        "YYYY-MM-DD"
      );
      this.dateModelMov.endDate = moment(this.dateMov.end).format("YYYY-MM-DD");

      this.$emit("dateChange", this.dateModelMov);

      return `${moment(this.dateMov.start).format("YYYY-MM-DD")} al ${moment(
        this.dateMov.end
      ).format("YYYY-MM-DD")}`;
    },
    modalDateRangeCalendarValid() {
      if (
        this.dateMov == "" ||
        this.dateMov == null ||
        this.dateMov == undefined
      ) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    await this.getListBranchOffice(2);
  },
  watch: {
    async search() {
      if (this.search == "") {
        await this.searchValue();
      }
    },
    options: {
      async handler() {
        await this.pagination();
      },
      deep: true,
    },
  },
  methods: {
    async typeMovementModuleFilterChange() {
      if (this.typeMovementModule) {
        this.pagesAtributes.sortFilters.movementsEntityFilter = this.typeMovementModule;
      } else {
        delete this.pagesAtributes.sortFilters.movementsEntityFilter;
      }
      await this.performSearch(this.pagesAtributes);
    },
    async datePickerChangeHandler() {
      if (this.dateMov) {
        let start = moment(this.dateMov.start).format("YYYY-MM-DD");
        let end = moment(this.dateMov.end).format("YYYY-MM-DD");

        this.pagesAtributes.sortFilters.movementsDateRangeFilter = {
          from: start + " 00:00:00",
          to: end + " 23:59:59",
        };
      } else {
        delete this.pagesAtributes.sortFilters.movementsDateRangeFilter;
      }
      await this.performSearch(this.pagesAtributes);
    },
    modalDateRangeClean() {
      this.dateMov = "";
      this.dateModelMov.startDate = "";
      this.dateModelMov.endDate = "";
    },
    detail(item) {
      this.$emit("detailMovement", item);
    },
    getNameEntity(targetValue) {
      const result = this.typeEntityMovement.find(
        ({ value }) => value == targetValue
      );
      return result ? result.text : "Sin definir";
    },
    getNameEntityModule(targetValue) {
      const result = this.typeModuleMovement.find(
        ({ value }) => value == targetValue
      );
      return result ? result.text : "Sin definir";
    },
    /**
     * Table pagination. / Paginado de la tabla.
     * @method
     */
    async pagination() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.pagesAtributes.sortBy = sortBy[0];
      this.pagesAtributes.sortDesc = sortDesc[0];
      this.pagesAtributes.currentPage = page - 1;
      this.pagesAtributes.itemsPerPage = itemsPerPage;
      await this.methodPage(this.pagesAtributes);
    },
    async typeMovementFilterChange() {
      if (this.typeMovement) {
        this.pagesAtributes.sortFilters.movementsOriginTypeFilter = this.typeMovement;
      } else {
        delete this.pagesAtributes.sortFilters.movementsOriginTypeFilter;
      }
      await this.performSearch(this.pagesAtributes);
    },
    async statusFilterEntityChange() {
      if (this.statusFilterEntity) {
        this.pagesAtributes.sortFilters.movementsOriginMovementFilter = this.statusFilterEntity;
      } else {
        delete this.pagesAtributes.sortFilters.movementsOriginMovementFilter;
      }
      await this.performSearch(this.pagesAtributes);
    },
    async searchValue() {
      try {
        this.loading_search = true;
        this.pagesAtributes.search = this.search;
        await this.performSearch(this.pagesAtributes);
        this.page = 1;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading_search = false;
      }
    },
    async getListBranchOffice(orderType, idUser) {
      try {
        this.loading = this.$UtilFront.getLoadding();
        this.isDisabled = true;
        const payload = {
          orderType: null,
          ...(idUser && { idUser }),
        };
        const response = await BranchOfficeRequest.listBranchOfficeUsers(
          payload
        );
        this.branchOfficeList = response.data;
        this.branchOfficeList.push({
          text: "MOVIEMIENTO GENERALES - (SIN SUCURSAL)",
          value: "0",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isDisabled = false;
        this.loading.hide();
      }
    },
    async userFilterChange(payload) {
      if (this.userFilter && payload) {
        this.pagesAtributes.sortFilters.movementsUserFilter = parseInt(
          this.userFilter
        );
      } else {
        if (!this.userFilter)
          delete this.pagesAtributes.sortFilters.movementsUserFilter;
      }
      if (payload) {
        this.isDisabled = true;
        await this.performSearch(this.pagesAtributes);
        this.isDisabled = false;
      }
    },
    async performSearch(pagesAtributes) {
      await this.methodPage(pagesAtributes);
    },
    async branchChange(item) {
      this.branch_general = item;
      this.userFilter = null;
      this.userFilterChange(false);
      if (item) {
        this.pagesAtributes.sortFilters.movementsBranchOfficeFilter = item;
      } else {
        delete this.pagesAtributes.sortFilters.movementsBranchOfficeFilter;
        await this.getListBranchOffice(2);
        this.branch_general = null;
      }
      if (!this.userFilter) await this.listAllUserFilter(item);
      await this.performSearch(this.pagesAtributes);
    },
    async listAllUserFilter(idBranchOffice) {
      const payload = {
        ...(idBranchOffice && { idBranchOffice }),
      };
      this.loading = this.$UtilFront.getLoadding();
      await OrderRequest.getAllUserList(payload)
        .then((response) => {
          this.listUserFilter = response.data;
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    downloadPDF(item) {
      if (this.shipments) return this.downloadShipmentPdf(item);
      this.downloadRemissionPDF(item);
    },
    downloadShipmentPdf(item) {
      let send =
        "idOrder=" + item.orderId + "&" + "idShipment=" + item.idShipment;
      ExcelRequest.reportOrderPdf(send);
      this.$toast.success("Generando documento PDF");
    },
    downloadRemissionPDF(item) {
      let send =
        "idRemision=" +
        item.idRemmision +
        "&" +
        "idSucursal=" +
        item.idBranchOfficeOrigin;
      ExcelRequest.reportRemmisionPdf(send);
      this.$toast.success("Descargando documento PDF");
    },
  },
};
</script>
