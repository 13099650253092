<template>
  <div id="containerbar">
    <div class="rightbar">
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-9 col-lg-9">
            <h4 class="page-title">
              Movimientos generales
            </h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("cancellation.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title">
                      <i class="feather icon-database mr-2"></i
                      >{{ $t(titleAction) }}
                    </h5>
                  </div>
                </div>
              </div>
              <MovementTableComponent
                v-if="showTable"
                :headers="headers"
                :pagesAtributes="pagesAtributes"
                :methodPage="listAllRemissionAudit"
                :modelTable="modelTable"
                @detailMovement="detailMovement"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <MovementModalDetail
      :model="model"
      v-bind:dialogM="dialogM"
      :headers="headers"
    />
  </div>
</template>

<script>
// Vuex

import { AuditRequest } from "@/core/request";
import MovementTableComponent from "@/components/audit/MovementTableComponent";
import MovementModalDetail from "@/components/audit/MovementModalDetail";

import typeEntityMovement from "@/assets/json/typeEntityMovement.json";
import typeModuleMovement from "@/assets/json/typeModuleMovement.json";

import { REMMISION_AUDIT_MOVEMENT } from "@/core/DataTableHeadersLocale";

export default {
  data() {
    return {
      title: "Movimientos",
      permissions: this.$PermissionConstants,
      headers: REMMISION_AUDIT_MOVEMENT(),
      showTable: true,
      loading: null,
      pagesAtributes: {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: true,
        sortFilters: {},
      },
      dialogM: {
        dialog: false,
      },
      model: {},
      modelTable: {
        content: [],
        pageable: {},
        totalPages: 0,
        totalElements: 0,
      },
      typeEntityMovement: typeEntityMovement,
      typeModuleMovement: typeModuleMovement
    };
  },
  computed: {
    titleAction() {
      if (this.type == 1) {
        return "Movimientos";
      }
      return "Movimientos";
    },
  },
  methods: {
    getNameEntity(targetValue) {
      const result = this.typeEntityMovement.find(
        ({ value }) => value == targetValue
      );
      return result ? result.text : "Sin definir";
    },
    getNameEntityModule(targetValue) {
      const result = this.typeModuleMovement.find(
        ({ value }) => value == targetValue
      );
      return result ? result.text : "Sin definir";
    },
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    alertModal(type, message) {
      this.$refs.alertMessageComponentModal.reloadComponent(type, message);
    },
    withPermissionName(namePermission) {
      return this.$UtilFront.withPermissionName(namePermission);
    },
    detailMovement(item) {
      this.model = {...item};
      this.model.dataMovement = JSON.parse(this.model.dataMovement);
      this.model.dataMovement = JSON.stringify(
        this.model.dataMovement,
        null,
        2
      );
      this.model.originMovement = this.getNameEntity(this.model.originMovement);
      this.model.entity = this.getNameEntityModule(this.model.entity);
      this.dialogM.dialog = true;
    },
    clearModel() {
      this.modelTable = {
        content: [],
        pageable: {},
        totalPages: 0,
        totalElements: 0,
      };
    },
    // Entradas
    async listAllRemissionAudit(pagesAtributes) {
      this.loading = this.$UtilFront.getLoadding();
      let payload = {
        type: 1,
        page: pagesAtributes,
        idUser: this.$UtilFront.getDataUser().idUser,
        idBranchOffice: this.$UtilFront.getDataUser().mainBranch,
      };
      await AuditRequest.getAllAuditMovement(payload)
        .then((response) => {
          let data = response.data;
          this.modelTable = data;
        })
        .catch((error) => {
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
  },
  watch: {
    async type() {
      this.showTable = false;
      this.pagesAtributes = {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: true,
        sortFilters: {},
      };
      this.$nextTick(() => {
        this.showTable = true;
      });
    },
  },
  components: {
    MovementTableComponent,
    MovementModalDetail,
  },
};
</script>
